import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useSpring, useTrail, animated } from '@react-spring/web'
import { Icon } from '/features/buildingBlocks/Icon'
import { HeadingXxl, HeadingXl } from '/features/buildingBlocks/Heading'
import { CtaButtonPrimary } from '/features/buildingBlocks/CtaButton'
import { useClientConfig } from '/machinery/ClientConfig'

import styles from './JobCards.css'

import iconArrow from '/images/icons/arrow-rotated.raw.svg'
import iconLocation from '/images/icons/location.raw.svg'

export function JobCards({ jobs, title }) {
  const config = useClientConfig()
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [0.5] })

  const headingSpring = useSpring({
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(40px)'
    },
    delay: 100,
    config: { tension: 100, friction: 35 }
  })
  const trail = useTrail(jobs?.length ?? 0, {
    delay: 200,
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0) scale(1)' : 'translateY(50%) scale(0.9)',
    },
    config: { tension: 150, friction: 35 },
  })

  return jobs && (
    <div id='jobcards' className={styles.component}>
      <div className={styles.header}>
        <animated.div style={headingSpring} ref={elementRef}>
          {(title.length < 20)
            ? <HeadingXxl h='2'>{title}</HeadingXxl>
            : <HeadingXl h='2'>{title}</HeadingXl>
          }
        </animated.div>
      </div>
      <div
        style={{ '--job-count': jobs?.length }}
        className={cx(styles.jobs, jobs?.length <= 3 && styles.shouldCenterJobs)}
      >
        {jobs?.map((job, i) => (
          <animated.div key={i} className={styles.job} style={trail[i]}>
            <JobCard
              url={job.url}
              title={job.title}
              location={job.location}
              key={i}
            />
          </animated.div>
        ))}
      </div>
      <CtaButtonPrimary
        layoutClassName={styles.ctaButton}
        href={config.careersLink.url}
        dataX='link-to-klm-careers'
        icon={iconArrow}
      >
        Check out all vacancies
      </CtaButtonPrimary>
    </div>
  )
}

function JobCard({ title, location, url }) {
  return (
    <div className={styles.componentJobCard}>
      <h3 className={styles.heading}>{title}</h3>
      <div className={cx(styles.info, styles.relativeToParent)}>
        <div className={styles.location}>
          <Icon icon={iconLocation} />
          <span className={styles.locationLabel}>{location}</span>
        </div>
        <a
          className={cx(styles.link, styles.relativeToParent)}
          href={url}
          data-x='link-to-external-careers'
          target='_blank'
          rel='noreferrer'
        >
          <Icon layoutClassName={styles.icon} icon={iconArrow} />
        </a>
      </div>
    </div>
  )
}
